import { useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import Derecha from '@/assets/icons/DerechaSinBorde.svg';
import Izquierda from '@/assets/icons/IzquierdaSinBorde.svg';
import Zoom from '@/assets/icons/zoom.svg';

import type { FunctionComponent } from 'react';
import type { TypeGaleriaProps } from './types';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const PopUpGaleria = dynamic(() => import('./PopUpGaleria'), {
  ssr: false,
});

/**
 * Galeria
 */
export const Galeria: FunctionComponent<TypeGaleriaProps> = ({
  className = '',
  images = [],
  heigth,
  roundedPosition = 'top',
  title,
  desc,
}: TypeGaleriaProps) => {
  const [open, setOpen] = useState(false);

  const showGallery = () => {
    setOpen(!open);
  };
  return (
    <>
      <PopUpGaleria
        title={title}
        images={images}
        setState={setOpen}
        state={open}
        desc={desc}
      />
      <div
        style={{
          height:
            roundedPosition === 'top'
              ? heigth
                ? `${heigth}px`
                : 'auto'
              : '100%',
        }}
        className={className}
      >
        <Swiper
          slidesPerView={1}
          navigation={{
            nextEl: '.Siguiente',
            prevEl: '.Anterior',
          }}
          modules={[Pagination, Navigation]}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          style={{ width: '100%', height: '100%' }}
          className={
            roundedPosition === 'top'
              ? 'rounded-t-md'
              : roundedPosition === 'left'
              ? 'rounded-r-md'
              : roundedPosition === 'all'
              ? 'rounded-md'
              : ''
          }
        >
          <div
            className="absolute right-2 top-2 z-10 h-8 w-8 cursor-pointer"
            onClick={showGallery}
          >
            <Zoom />
          </div>

          <div className="absolute top-1/2 z-20 flex w-full -translate-y-1/2 flex-row items-center justify-between px-2 text-primary-400">
            <Izquierda
              width={30}
              className="Anterior cursor-pointer"
              role="button"
            />
            <Derecha
              width={30}
              className="Siguiente cursor-pointer"
              role="button"
            />
          </div>

          {images?.map((item, index) => (
            <SwiperSlide key={index} style={{ transform: 'none' }}>
              <div className="relative h-full w-full">
                <Image
                  src={item}
                  objectFit="cover"
                  layout="fill"
                  alt="image of card"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

Galeria.displayName = 'Galeria';
